import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { forgetPassword } from "../../redux/actions";
import { useSelector } from 'react-redux';

const ForgetPassword = ({ setLoading, login, forgetPassword }) => {
	const history = useHistory();
	const confige = useSelector((state) => state.app.config);
	React.useEffect(() => {
		if (confige.campaignState === 'partialExpired' || confige.submissionEnded === 'submissionExpired') {
			history.push('/');
			toast.error(`Sorry! You are not allowed to access this page. Promotion is ended.`);
		  }
	}, [confige]);

	return (
		<main id="main">
		<div className="register-page">
			<Card>
				<div className="row justify-content-center">
					<div className="col-lg-6 col-md-8">
						<Formik
							initialValues={{ email: "" }}
							validationSchema={Yup.object({
								email: Yup.string()
									.email("Please enter a valid email address.")
									.required("Please enter a valid email address."),
							})}
							onSubmit={(values, { setSubmitting }) => {
								//console.log(values);
								setLoading(true);
								forgetPassword(values.email)
									.then((response) => {
										//console.log(response);
										setSubmitting(false);
										setLoading(false);
										toast.success("Password reset link has been sent to your registered email. Please check your mail box.");
										history.push("/login");
									})
									.catch((error) => {
										console.log(error);
										setSubmitting(false);
										setLoading(false);
										error.forEach((item) => {
											toast.error(item.message);
										});
									});

								// setTimeout(() => {
								// 	alert(JSON.stringify(values, null, 2));
								// 	setSubmitting(false);
								// }, 400);
							}}>
							<Form aria-label="Forgot password">
								<h1 className="main-heading pb-3 text-center">Forgot your password?</h1>
								<div className=" row justify-content-center">
									<div className="col">
										<div className="form-group row theme-form-email justify-content-center">
											<label htmlFor="staticEmail" className="col-12 col-form-label input_label">
												Email Address
											</label>
											<div className="col-12">
												<Field data-test="reset-password" name="email" type="email" id="staticEmail" className="form-control" aria-label="Enter your registered email"	aria-autocomplete="your-email" />

												<div className="input-error" role="alert">
													<ErrorMessage name="email" />
												</div>
												{/* <input data-test="reset-password" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  className="form-control" id="staticEmail" placeholder="Email" /> */}
											</div>
										</div>

										<div className="row submit mt-3 justify-content-center">
											<button role="submit" type="submit" className="btn">
												Generate New Password
											</button>
										</div>
									</div>
								</div>
							</Form>
						</Formik>
					</div>
				</div>
			</Card>
		</div>
		</main>
	);
};

export default connect(null, { forgetPassword })(IsLoadingHOC(ForgetPassword, "Wait ...."));
