import React from "react";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

const TermsAndCondition = (props) => {
	return (
        <>	
        <main id="main">
            <div className="container">
                <div className="row justify-content-md-center section-content" id="termsandcondition">
                    <Col md={12}>
                        <div className="text-center py-4">
                            <h2>Oral B & Mastercard® Spend More & Get More Offer</h2>
                            <h1>Terms & Conditions</h1>
                        </div>
                        <div className="mb-5 mt-3 text-left">
                            <p>Purchase participating Oral-B Electronic Toothbrushes and/or and Electric Brush Head Refill products* in a single
                                transaction with your Mastercard® between November 1, 2022 12:00 AM Eastern Time (“ET”) and December 31, 2022
                                11:59 PM ET (“Offer Period”) at a Canadian in-store or online retailer and receive a Prepaid Mastercard by email as
                                outlined below:
                            </p>
                            <Col md={12}>
                                <div className="mt-2 mb-5 justify-content-center">
                                    <table className="table table-striped table-bordered text-center">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>
                                                    <h3>SPEND TOTAL</h3>
                                                    <p className="mb-0">before taxes and after any coupon or discount applied in a single transaction</p>
                                                </th>
                                                <th>
                                                    <h3>GET</h3>
                                                    <p className="mb-0">a Prepaid Mastercard valued at</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>$200 or more</td>
                                                <td>$60 CAD</td>
                                            </tr>
                                            <tr>
                                                <td>$150-$199</td>
                                                <td>$35 CAD</td>
                                            </tr>
                                            <tr>
                                                <td>$100-$149</td>
                                                <td>$20 CAD</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <p>**Participating products: Oral-B iO series, Genius Series, Smart Series, Pro 2000 Twin Pack, Gum & Sensitive Care, and Pro 1000 Electric Toothbrushes. Includes all variants of Kids Electric Toothbrushes and Oral-B Electric Toothbrush refill brush heads. <strong>Excludes Oral-B Pro 500 & Vitality Electric toothbrushes, Oral-B Manual and Battery toothbrushes.</strong>  Availability of participating products are subject to inventory and varies by retailer.</p>
                            <p>Maximum one (1) Reward per approved submission. Limit two (2) Reward(s) per person. Open to Canadian residents, excluding residents of Quebec, 19 years of age or older. Receive an email with instructions on how to claim your Prepaid Mastercard, subject to verification of eligibility and compliance with these Terms and Conditions. While supplies last.</p>
                            <p>Online submissions only. Submissions must be completed by January 31, 2023 11:59 PM ET. Proof of purchase required which includes original dated receipt or ship confirmation for online orders or confirmation order for pick up orders (“Receipt”). Qualifying purchases must be made in a single transaction during the Offer Period for purposes of reaching the qualifying total purchase amount (after any coupon or discount applied and before applicable taxes). Each Receipt may only be used once and be uploaded in a single photo (jpg, gif, PNG file types with a maximum file size of 5 MB) with a clear and legible image that includes the retailer name, time and date of purchase, qualifying products and payment method (“Image Requirements"). If a submission is incomplete or fails to meet any of the Terms and Conditions, no prepaid card will be issued. Receipts obtained through unauthorized means or illegitimate channels will be void. Receipts that cannot be verified for any reason will be rejected.</p>
                            <p>Prepaid card can be used everywhere Mastercard is accepted. E-Vouchers are accepted for online purchases only. Not redeemable as cash or usable at ATMs or gas pumps. Prepaid cards are not reloadable or insured. Prepaid card expires 6 months from issuance. Terms, including applicable fees, of prepaid card apply. Card acceptance may vary by retailer.</p>
                            <p>For physical prepaid cards, only valid Canadian Postal mailing addresses are acceptable. No P.O. Boxes allowed.</p>
                            <p>Participants who requested a digital prepaid card are responsible to check their email account, as provided in the registration form, including spam or junk folders. P&G does not assume any responsibility nor is responsible for electronic communications which are undelivered for any reason, including (but not limited to) as a result of any form of active or passive filtering of any kind or insufficient space in registrant’s mailbox to receive e-mail messages. Participants selecting this option provide their express consent to electronic communications with P&G for the purposes of administrating this offer.</p>
                            <p>Multiple submissions will not be acknowledged or returned. If original dated in-store purchase receipt and confirmation form online orders or store pickup orders, are not included in the offer submission and/or does not clearly include the Image Requirements, the prepaid card will not be issued.</p>
                            <p>Reproduction, alteration, sale, trade, or purchase of any Receipt is prohibited. Proof of purchase must be obtained from product purchased by you. No requests from groups, clubs, or organizations will be honoured. We will not honour incomplete submissions. P&G not responsible for lost, late, misdirected, delayed, incomplete, illegible or undelivered submissions.</p>
                            <p>Trust is a cornerstone of our corporate mission. P&G is committed to maintaining your trust by protecting personal information we collect about you. Read our Privacy Policy at www.pg.com/privacy/english/privacy_notice.html.</p>
                            <p>Please allow up to 6 weeks for delivery. For any questions regarding this offer please contact us at <a className="btn-link" href="mailto:support@receiptprocessor.com">support@receiptprocessor.com</a>.</p>
                            <p>Use your card everywhere Mastercard is accepted worldwide. Card is issued by Peoples Trust Company pursuant to licence by Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. Your use of the prepaid card is governed by the Cardholder Agreement, and some fees may apply. This is not a gift card. Please note that prepaid cards are subject to expiration, so pay close attention to the expiration date of the card.</p>
                            <p>Use your Prepaid Mastercard everywhere Mastercard is accepted worldwide. 6-month expiration. Digital or physical card.</p>
                        </div>
                    </Col>
                </div>
            </div>
        </main>
        </>
	);
};

export default TermsAndCondition;