import React from "react";
import { useSelector } from "react-redux";
import GoogleCaptchaV2 from "./captcha/GoogleCaptchaV2";
import GoogleCaptchaV3 from "./captcha/GoogleCaptchaV3";
const Captcha = (props) => {
    const config = useSelector((state) => state.app.config);
    if(config.captchaConfig.recaptchaVersion === 'V2'){
        return <GoogleCaptchaV2 {...props}/>
    } else if(config.captchaConfig.recaptchaVersion === 'V3'){
        return <GoogleCaptchaV3 {...props}/>
    } else {
        return <GoogleCaptchaV2 {...props}/>
    }
}

export default Captcha;
