import React, { Fragment } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

export default ({ children }) => (
	<Fragment>
		<a className="skiptocontent" href="#main">skip to content</a>
		<Header />
		
			{children}
		
		<Footer />
	</Fragment>
);
