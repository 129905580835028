import React from "react";
import { Row, Col } from "react-bootstrap";
class ParticipateInstructions extends React.Component {
	render() {
		return (
			<main id="main">
			<Row className="justify-content-md-center text-center section-content" id="participate-content" aria-label="content">
				<Col md={9}>
					<h1>How To Participate</h1>
				</Col>
				<Col md={9}>
					
					<h2>
						1. BUY
					</h2>
					<p>
						Purchase one participating product between 11/01/2022 and 12/31/2022 and register.
					</p>
					
					<h2>
						2. TAKE A PICTURE
					</h2>
					<p>
						Snap a photo of your receipt showing your qualifying purchase(s). Don’t forget that the photo of each receipt must display the date and time of the transaction and the qualifying products purchased. If the receipt is too long, please fold the receipt so that the date and time and qualifying purchases are included in the picture.
					</p>

					<h2>
						3. REGISTER & UPLOAD
					</h2>
					<p>
						To register, please click ‘REGISTER’ on the navigation menu above. Once you complete registration, you will be prompted to upload your receipt. Look out for an email confirming your receipt was uploaded and received. Receipts can take up to two business days to process and will be subject to verification before approval is received, so please be patient. All eligible receipts MUST be uploaded by 1/31/2023.
					</p>
					
				</Col>
			</Row>
			</main>
		);
	}
}

export default ParticipateInstructions;
