import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert, Card, Toast } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import IsLoadingHOC from "./IsLoadingHOC";
import { helpQueryAction } from "../redux/actions";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Captcha from "./Captcha";
import { useSelector } from "react-redux";
const Support = (props) => {
	const { t } = useTranslation();
	const [data, setData] = useState({
		name: "",
		email: "",
		description: "",
		captchaVal: "",
	});
	const config = useSelector((state) => state.app.config);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	const [error, setError] = useState({ name: "", email: "", description: "", captchaVal: "" });
	// eslint-disable-next-line
	const validationError = { description: "", name: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, lastName: /^[A-Za-z]+$/, captchaVal: "" };
	let [captchaEnable, setCaptchaEnable] = useState(false);
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captchaVal"] = "";
			let reqd = JSON.parse(JSON.stringify(data));
			reqd.captchaVal = captchaValue;
			setData(reqd);
		}
		setError(errorArray);
		// eslint-disable-next-line
	}, [captchaValue]);
	useEffect(() => {
		if (config.captchaConfig) {
			if (config.captchaConfig.supportCaptcha) {
				setCaptchaEnable(true);
			}
		}
	}, [config]);
	const handleChange = (e) => {
		let key = e.target.name;
		let val = e.target.value;
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		if (val.match(validationError[key]) || val === "" || key === "email") {
			reqd[key] = val;
		}
		if (key === "name") {
			if (!reqd[key] || reqd[key].trim().length < 1) {
				errd[key] = `Name is required`;
			} else {
				errd[key] = ``;
			}
		} else if (key === "email") {
			if (val==="") {
				errd[key] = `Email is required.`;
			}else if (!reqd[key].match(validationError[key])) {
				errd[key] = `Please enter a valid email address.`;
			} else {
				errd[key] = ``;
			}
		} else if (key === "description") {
			if (!reqd[key] || val.trim().length < 1) {
				errd[key] = `Message is required`;
			} else {
				errd[key] = ``;
			}
		}
		setError(errd);
		setData(reqd);
	};
	const onSubmit = (e) => {
		e.preventDefault();
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		let isError = false;
		for (let key in reqd) {
			let val = reqd[key].trim();
			if (key === "name") {
				if (!val || val.length < 1) {
					isError = true;
					errd[key] = `Name is required.`;
				} else {
					errd[key] = ``;
				}
			} else if (key === "email") {
				if (val==="") {
					errd[key] = `Email is required.`;
				}else if (!val.match(validationError[key])) {
					errd[key] = `Please enter a valid email address.`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			} else if (key === "description") {
				if (!val || val.length < 1) {
					errd[key] = `Message is required.`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			} else if (key === "captchaVal" && captchaEnable) {
				if (!val || val.length < 2) {
					errd[key] = `Please check Captcha.`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			}
		}
		setError(errd);
		if (!isError) {
			props.setLoading(true);
			let requestData = {
				email: reqd.email,
				name: reqd.name,
				message: `${reqd.description}`,
			};
			if (captchaEnable) {
				requestData["captchaVal"] = reqd.captchaVal;
			}
			helpQueryAction(requestData)
				.then((response) => {
					for (let key in reqd) {
						reqd[key] = "";
					}
					setResetCaptchaValue(resetCaptchaValue + 1);
					setData(reqd);
					props.setLoading(false);
					toast.success(`Thank you! All messages are responded to within 3 business days in the order they are received.`);
				})
				.catch((error) => {
					setResetCaptchaValue(resetCaptchaValue + 1);
					toast.error(t("Something went wrong."));
					props.setLoading(false);
				});
		}
	};

	return (
		<main id="main">
		<Container className="justify-content-md-center" id="support-content">
			<div className="register-page support_page">
				<Card>
					<Form aria-label="Support" method="post">
						<h1 className="main-heading text-center">{t("Support")}</h1>
						<p className="text-center">
							If you have any inquiries or trouble registering, please message us below.<br></br> Note - This is not a chatbox.
						</p>
						<Row md={1} className="mt-5 ">
							

							{/*[START:FIRSTNAME]*/}
							<Col className="form-group col-12 col-md-6 theme-signup-firstname">
								<TextField 
									label="Name" 
									className="textfield" 
									error={error.name === "" ? false : true} 
									id="name" 
									value={data.name} 
									helperText={error.name} 
									variant="standard" 
									name="name" 
									onChange={handleChange} 
									onFocus={handleChange} 
									inputProps={{
										"aria-label": 'Your Name',
										"aria-autocomplete": 'name'
									}}
									autoComplete="name"
								/>
							</Col>
							{/*[END:FIRSTNAME]*/}

							{/*[START:EMAIL]*/}
							<Col className="form-group col-12 col-md-6 theme-signup-email">
								<TextField 
									label="Email Address" 
									variant="standard" 
									className="textfield float-right" 
									error={error.email === "" ? false : true} 
									id="email" 
									value={data.email} 
									helperText={error.email} 
									name="email" 
									onChange={handleChange} 
									onFocus={handleChange} 
									inputProps={{
										"aria-label": 'Your Email',
										"aria-autocomplete": 'email'
									}}
									autoComplete="email"
								/>
							</Col>
							{/*[END:EMAIL]*/}
						</Row>
						<Row md={1}>
							<Col className="form-group theme-signup-email">
								<TextField 
									label="Message" 
									// multiline 
									className="textfield float-right" 
									error={error.description === "" ? false : true} 
									id="description" 
									value={data.description} 
									helperText={error.description} 
									variant="standard" 
									name="description" 
									onChange={handleChange} 
									onFocus={handleChange} 
									inputProps={{
										"aria-label": 'Type your message or query'
									}}
								/>
							</Col>
						</Row>

						{captchaEnable ? (
							<Row md={1} className="text-left captcha-left">
								<Col className="text-left">
								<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">Google captcha</label>
									<Captcha  reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
									<div className="input-error" style={{ marginTop: "-10px" }}  role="alert">
										{error.captchaVal}
									</div>
								</Col>
							</Row>
						) : (
							""
						)}
						<Row md={1}>
							<Col className="submit pt-3 text-left">
								<Button variant="primary" className="themeBackground" size="lg" id="register-submit-btn" type="submit" role="submit" onClick={onSubmit}>
									<Trans>Submit</Trans>
								</Button>
							</Col>
						</Row>
					</Form>
				</Card>
			</div>
		</Container>
		</main>
	);
};

export default IsLoadingHOC(Support, "Wait .....");
