import React, { useState,useEffect } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TopRight from "./TopRight";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { saveSelectedMenu } from "../redux/actions";

const Menu = ({ data }) => {
	const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	const config = useSelector(state => state.app.config);
	const [isActiveSite, setIsActiveSite] = useState(true);
	useEffect(() => {
	  if (config.campaignState === 'partialExpired' || config.submissionEnded === 'submissionExpired') {
		setIsActiveSite(false);
	  }
	  //eslint-disable-next-line
	}, [config]);

	const dispatch = useDispatch();

	const scroll = (el) => {
		dispatch(saveSelectedMenu(el.id));
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -80;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
		document.getElementById("navbarSupportedContent").classList.remove("show");
	};

	const navslide = () => {
		document.getElementById("navbarSupportedContent").classList.remove("show");
	}

	
	return (
		<Fragment>
			<button id="button-navbar-toggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span id="span-navbar-toggler-icon" className="navbar-toggler-icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
						<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
					</svg>
				</span>
			</button>

			<div className="collapse navbar-collapse mx-auto" id="navbarSupportedContent">
				<ul className="navbar-nav">
					<li className="nav-item">
						<a className="nav-link nav-link-custom" id="href-home" href="/">
							{t("Home")}
						</a>
					</li>

					{isActiveSite?<li className="nav-item ">
						<HashLink className="nav-link nav-link-custom" id="href-participate" to="/#participate-content" scroll={scroll}>
							{t("How To Participate")}
						</HashLink>

						{/* <Link className="nav-link nav-link-custom" id="href-participate" to="/#participate-content">
							{t("How To Participate")}
						</Link> */}
					</li>:""}

					{isActiveSite?!user.accessToken ? (
						<li className="nav-item ">
							<a className="nav-link nav-link-custom" id="href-registration" href="/register">
								{t("Register")}
							</a>
						</li>
					) : (
						<li className="nav-item">
							<Link id="href-receipt-upload" className="nav-link nav-link-custom" to="/upload-receipt" onClick={navslide}>
								{t("Upload")}
							</Link>
						</li>
					):""}
					{isActiveSite?<TopRight />:""}

					<li className="nav-item">
						<Link className="nav-link nav-link-custom" id="href-faq" to="/faq" onClick={navslide}>
							{t("FAQ")}{" "}
						</Link>
					</li>

					<li className="nav-item">
						<Link className="nav-link nav-link-custom" id="href-support" to="/support" onClick={navslide} >
							{t("Support")}{" "}
						</Link>
					</li>
					<li className="adchoices-link">
						<a className="nav-link" href="https://privacypolicy.pg.com/en/#iba" target="_blank" >
							AdChoices<img src="/public/assets/images/AdChoicesicon.png" alt="Adchoices logo" className="adchoices" />
						</a>
					</li>
					{/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}
				</ul>
				
			</div>
			
		</Fragment>
	);
};

export default Menu;
