import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container, Button, FormGroup, Modal } from "react-bootstrap";
import { signup, rewardsListUnSecureAction, login, preRegistrationAction } from "../../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GooglePlaces from "../../components/GooglePlaces";
//import Calendar from "react-calendar";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import Required from "../../components/Required";
import Captcha from "../../components/Captcha";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { Input, TextField, createTheme, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
var validator = require("validator");
const formLabelsTheme = createTheme({
	overrides: {
		MuiFormLabel: {
			asterisk: {
				color: "#db3327",
				"&$error": {
					color: "#db3327",
				},
			},
		},
	},
});
const isValidDate = (date) => {
	// eslint-disable-next-line
	const matches = /^(\d{1,2})[/\/](\d{1,2})[/\/](\d{4})$/.exec(date);
	if (matches === null) {
		return false;
	}
	const [_, m, d, y] = matches;
	const composedDate = new Date(+y, +m - 1, +d);
	return composedDate.getDate() === +d && composedDate.getMonth() === +m - 1 && composedDate.getFullYear() === +y;
};
const Register = ({ signup, setLoading, login }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const [place, setPlace] = useState({});
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	let [captchaValue, setCaptchaValue] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	let rewardTypeList = [{"id":"digital",name:"Digital"},{"id":"physical",name:"Physical"},];
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		window.location.href = "/";
	};
	const handleShow = () => setShow(true);
	const errorMessages = {
		firstName: { reqMsg: "Please enter your first name.", invalidMsg: "Minimum 2 characters, maximum 100 characters. Special characters are allowed." },
		lastName: { reqMsg: "Please enter your last name.", invalidMsg: "Minimum 2 characters, maximum 100 characters. Special characters are allowed." },
		email: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		confirmEmail: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		city: { reqMsg: "Please enter your city.", invalidMsg: "Please enter a valid city name." },
		address: { reqMsg: "Please enter your address.", invalidMsg: "Please enter a valid address." },
		suite: { reqMsg: "Please enter your SUITE/APT. NO.", invalidMsg: "Please enter a valid SUITE/APT. NO." },
		state: { reqMsg: "Please enter your state.", invalidMsg: "Please enter a valid state." },
		zip: { reqMsg: "Please enter your zip code.", invalidMsg: "Please enter a valid zip code." },
		birth: { reqMsg: "Please enter your valid DOB.", invalidMsg: "Please enter your valid DOB." },
		password:{reqMsg:"Password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters.",invalidMsg:"Password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters."},
		passwordConfirm:{reqMsg:"Confirm password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters.",invalidMsg:"Confirm password must be at least 8 characters long, contain at least one number or special characters and have a mixture of uppercase and lowercase letters."},
	};

	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		password: "",
		passwordConfirm: "",
		username: "",
		city: "",
		address: "",
		suite: "",
		state: "",
		zip: "",
		birth: "",
		latitude: 0,
		longitude: 0,
		additionalInfo: {
			visitorId: "",
			agree_to_opt_in: false,
			rewardType:""
		},
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({
		firstName: "",
		email: "",
		confirmEmail: "",
		homePhone: "",
		cellPhone: "",
		lastName: "",
		username: "",
		address: "",
		country: "",
		state: "",
		suite: "",
		zip: "",
		city: "",
		shippingInstructions: "",
		receiveEmail: "",
		receiveSms: "",
		gender: "",
		password: "",
		passwordConfirm: "",
		termCondition: "",
		captcha: "",
	});
	const [underAge, setUnderAge] = useState(false);
	useEffect(() => {
		if (confige.campaignState === "partialExpired" || confige.submissionEnded === "submissionExpired") {
			history.push("/");
			toast.error(`Sorry! You are not allowed to access this page. Promotion is ended.`);
		}
		let items = {};
		let oldState = { ...data, password: "", passwordConfirm: "" };
		setData(oldState);
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
				if (element.name === "email") {
					items["confirmEmail"] = element.validation;
				}
			});
			//console.log("password", confige.uiConfig.passwordRule);
			items.password = confige.uiConfig.passwordRule;
			items.passwordConfirm = confige.uiConfig.passwordRule;
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({
						apiKey: confige.fingerprinting.clientKey,
					});
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.registrationCaptcha) {
				setCaptchaEnable(true);
			}
		}
		setValidation(items);
	}, [confige, confige.uiConfig]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		//console.log(name, validation);
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		if (name === "agree_to_opt") {
			let d = JSON.parse(JSON.stringify(data));
			d.additionalInfo.agree_to_opt_in = e.target.checked;
			setData(d);
			return true;
		}
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: (`${t("Should not be less then", {
								count: validationArray[key],
						  })}.`);
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: (`${t("Should not be greater then", {
								count: validationArray[key],
						  })}.`);
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : (`${t("Should not be empty")}.`);
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = (`${t("Should be contain number")}.`);
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
				errorMessage = (`${t("Should be contain alphabets")}.`);
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : (`${t("Please enter a valid email")}.`);
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : (`${t("Should not be contain number")}.`);
				setError({ ...error, [name]: errorMessage });
				check = true;
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : (`${t("Should be contain number")}.`);
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = (`${t("Should have atleast one Upper Case latter")}.`);
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = (`${t("Should have atleast one Lower Case latter")}.`);
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = (`${t("Should have atleast one special letter")}.`);
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}

			if (name === "passwordConfirm") {
				if (data.password !== value) {
					setError({
						...error,
						passwordConfirm: (`${t("Password and confirm password should be same")}.`),
					});
				}
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captcha"] = "";
		}
		setError(errorArray);
	}, [captchaValue]);
	useEffect(() => {
		if (localStorage.getItem("allowReg")) {
			let regDataArr = window.atob(localStorage.getItem("allowReg")).split("|");
			if (parseInt(regDataArr[1]) === 1) {
				const newData = { ...data };
				newData.birth = regDataArr[0];
				setData(newData);
				setUnderAge(true);
			}
		}
	}, [localStorage.getItem("allowReg")]);
	const onSubmit = async (e) => {
		e.preventDefault();
		let errorArray = {};
		if (data.birth.length !== 0) {
			if (isValidDate(data.birth)) {
				let dt = new Date("2004-10-31");
				if (dt < new Date(data.birth)) {
					handleShow();
					localStorage.setItem("allowReg", window.btoa(`${data.birth}|1`));
					return false;
				}
			}
		}
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (name === "birth" && isNaN(new Date(value)) && value.length !== 0) {
				if (!isValidDate(value)) {
					errorMessage = "Enter a valid date in MM/DD/YYYY format.";
				} else {
					errorMessage = errorMessages.birth ? errorMessages.birth.invalidMsg : `${t("Invalid")} ${t("Birth Date")}.`;
				}
			} else if (name === "birth") {
				errorMessage = "";
			}
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : (`${t("Please enter a valid email")}.`);
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: (`${t("Should not be less then", {
								count: validationArray[key],
						  })}.`);
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: (`${t("Should not be greater then", {
								count: validationArray[key],
						  })}.`);
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : (`${t("Should not be contain number")}.`);
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : (`${t("Should be contain number")}.`);
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : (`${t("Should not be empty")}.`);
				} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = (`${t("Should have atleast one Upper Case latter")}.`);
				} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = (`${t("Should have atleast one Lower Case latter")}.`);
				} else if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
					errorMessage = (`${t("Should be contain number")}.`);
				} else if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
					errorMessage = (`${t("Should be contain alphabets")}.`);
				} else if (key === "specialCharReq" && value.length !== 0) {
					var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
					if (!format.test(value)) {
						errorMessage = (`${t("Should have atleast one special letter")}.`);
					}
				}
			}
			errorArray[name] = errorMessage;
			if (name === "password" || name === "passwordConfirm") {
				if (data["password"] !== data["passwordConfirm"]) {
					errorMessage = t("Password and confirm password should be same.");
				}
				errorArray["passwordConfirm"] = errorMessage;
			}
			if (name === "confirmEmail") {
				if (data["email"] !== data["confirmEmail"]) {
					errorMessage = t("Email and confirm email should be same.");
				}
				errorArray["confirmEmail"] = errorMessage;
			}
		}
		if (!captchaValue && captchaEnable) {
			errorArray["captcha"] = t("Please select captcha.");
		}
		if (!data.additionalInfo.rewardType) {
			errorArray["rewardType"] = t("Please select reward type.");
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue && captchaEnable) {
			return false;
		}

		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		delete newData.passwordConfirm;
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
							setLoading(false);
							setResetCaptchaCnt(resetCaptchaCnt + 1);
							setCaptchaValue(false);
						}
					} else {
						toast.error(t("Something went wrong"));
						setLoading(false);
						setResetCaptchaCnt(resetCaptchaCnt + 1);
						setCaptchaValue(false);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid);
					let loginPayload = { username: newData.email, password: newData.password, skipCaptchaVal: true, visitorId: newData.additionalInfo.visitorId, requestId: newData.additionalInfo.requestId };
					if (captchaValue !== "none") {
						loginPayload["captchaVal"] = captchaValue;
					}
					login(loginPayload)
						.then((resp) => {
							setResetCaptchaCnt(resetCaptchaCnt + 1);
							setCaptchaValue(false);
							toast.success("Thank you! You have been registered for the promotion.");
							history.push("/upload-receipt");
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
						})
						.catch((err) => {
							setResetCaptchaCnt(resetCaptchaCnt + 1);
							setCaptchaValue(false);
							toast.success(signupResp);
							history.push("/login");
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
						});
				}
			} catch (error) {
				setResetCaptchaCnt(resetCaptchaCnt + 1);
				setCaptchaValue(false);
				console.log("errror", error);
				setLoading(false);
				if (Array.isArray(error)) {
					error.map((item) => {
						toast.error(item.message);
					});
				} else {
					toast.error(t("Something went wrong"));
				}
			}
		}
	};
	const handleDate = (value) => {
		let errorMessage = "";
		if (isNaN(new Date(value)) && value.length !== 0) {
			if (!isValidDate(value)) {
				errorMessage = "Enter a valid date in MM/DD/YYYY format.";
			} else {
				errorMessage = errorMessages.birth ? errorMessages.birth.invalidMsg : `${t("Invalid")} ${t("Birth Date")}.`;
			}
		}
		let e = JSON.parse(JSON.stringify(error));
		e.birth = errorMessage;
		setError(e);
		setData({ ...data, birth: value });
	};
	useEffect(() => {
		if (place.hasOwnProperty("address")) {
			let curStateData = Object.assign({}, data);
			let curErrData = Object.assign({}, error);
			if (curStateData.hasOwnProperty("address")) {
				curStateData.address = place.address;
				curErrData.address = place.address ? "" : curErrData.address;
			}
			// if (curStateData.hasOwnProperty("country")) {
			// 	curStateData.country = place.country;
			// }
			if (curStateData.hasOwnProperty("state")) {
				curStateData.state = place.state;
				curErrData.state = place.state ? "" : curErrData.state;
			}
			if (curStateData.hasOwnProperty("city")) {
				curStateData.city = place.city;
				curErrData.city = place.city ? "" : curErrData.city;
			}
			if (curStateData.hasOwnProperty("suite")) {
				curStateData.suite = place.street;
				curErrData.street = place.street ? "" : curErrData.street;
			}
			if (curStateData.hasOwnProperty("zip")) {
				curStateData.zip = place.zip;
				curErrData.zip = place.city ? "" : curErrData.zip;
			}
			if (curStateData.hasOwnProperty("latitude")) {
				curStateData.latitude = place.latitude;
			}
			if (curStateData.hasOwnProperty("longitude")) {
				curStateData.longitude = place.longitude;
			}
			setError(curErrData);
			setData(curStateData);
		}
	}, [place]);
	const googleSelectedAddressHandler = (place) => {
		setPlace(place);
	};
	
	const tooltip = <Tooltip id="tooltip">Please select you mailing address. Select the proper address from the dropdown.</Tooltip>;
	const formatChars = {
		'1': '[12]',
		'0': '[0123456789]'
	  };
	const handleChangeAdditional =(e)=>{
		const {name,value}=e.target;
		let fd=JSON.parse(JSON.stringify(data));
		fd.additionalInfo[name]=value;
		setData(fd);
		if(name==="rewardType"){
			setError({...error,rewardType:value?"":"Please select a Reward."});
		}
	}
	return (
		<main id="main">
		<Container className="reg-page register-page">
			<MuiThemeProvider theme={formLabelsTheme}>
				<Card>
					<form aria-label="create account" method="post">
						<h1 className="text-center mt-3 mb-3">{t("Register")}</h1>
						<div className="register-links text-center mt-0 mb-5">
								Already registered?{" "}
								<a href="/login" aria-label="Click to register">
									<Trans>Click here</Trans>
								</a>{" "}
								to login
							</div>
						<Row>
							<Col md={6}>
								<FormGroup>
									{/*[START:FIRSTNAME]*/}
									<TextField
										label={"First Name"}
										className="textfield"
										required={validation["firstName"] && validation["firstName"].mandatory}
										error={error.firstName ? true : false}
										id="firstName"
										helperText={error.firstName}
										InputLabelProps={{ shrink: true }}
										name="firstName"
										onChange={handleChange}
										onFocus={handleChange}
										// placeholder={"First Name"}
										inputProps={{
											"aria-label": 'Your Name',
											"aria-autocomplete": 'name'
										}}
										autoComplete="name"
										// margin="normal"
									/>
									{/*[END:FIRSTNAME]*/}
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									{/*[START:LASTNAME]*/}
									<TextField
										label={"Last Name"}
										className="textfield"
										required={validation["lastName"] && validation["lastName"].mandatory}
										error={error.firstName ? true : false}
										id="lastName"
										helperText={error.lastName}
										InputLabelProps={{ shrink: true }}
										name="lastName"
										onChange={handleChange}
										onFocus={handleChange}
										//placeholder={"Last Name"}
										inputProps={{
											"aria-label": 'Last Name',
											"aria-autocomplete": 'family-name'
										}}
										autoComplete="family-name"
									/>
									{/*[END:LASTNAME]*/}
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<FormGroup>
									<TextField
										label={t("lang_key_email")}
										className="textfield"
										required={validation["email"] && validation["email"].mandatory}
										error={error.email ? true : false}
										helperText={error.email}
										InputLabelProps={{
											shrink: true,
										}}
										name="email"
										id="email"
										onChange={handleChange}
										onFocus={handleChange}
										//placeholder={"Email"}
										inputProps={{
											"aria-label": 'Your Email',
											"aria-autocomplete": 'email'
										}}
										autoComplete="email"
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<TextField
										label={t("Confirm Email")}
										className="textfield"
										required={validation["confirmEmail"] && validation["confirmEmail"].mandatory}
										error={error.confirmEmail ? true : false}
										helperText={error.confirmEmail}
										InputLabelProps={{
											shrink: true,
										}}
										name="confirmEmail"
										id="confirmEmail"
										onChange={handleChange}
										onFocus={handleChange}
										//placeholder={"Confirm Email"}
										inputProps={{
											"aria-label": 'Re-enter Email',
											"aria-autocomplete": 'confirm-email'
										}}
										autoComplete="email"
									/>
								</FormGroup>
							</Col>
						</Row>

						<Row>
							<Col md={6}>
								<div className="form-group ">
									<label htmlFor="password" className="col-form-label">
										<Trans>password</Trans>
										{validation["password"] && validation["password"].mandatory ? <Required /> : ""}
									</label>
									<div>
										<input
											type="password"
											name="password"
											onChange={handleChange}
											className="form-control"
											id="password"
											maxLength={validation["password"] && validation["password"].maxLength?validation["password"].maxLength:20}
											// placeholder={t("password")}
											aria-label="Enter password"
											autoComplete="password"
										/>
										<span className="input-error" role="alert">{error.password}</span>
									</div>
								</div>
							</Col>
							<Col md={6}>
								<div className="form-group">
									<label htmlFor="password-confirm" className="col-form-label">
										<Trans>confirm password</Trans>
										{validation["passwordConfirm"] && validation["passwordConfirm"].mandatory ? <Required /> : ""}
									</label>
									<div>
										<input
											type="password"
											name="passwordConfirm"
											onChange={handleChange}
											className="form-control"
											id="password-confirm"
											maxLength={validation["passwordConfirm"] && validation["passwordConfirm"].maxLength?validation["passwordConfirm"].maxLength:20}
											aria-label="Re-enter to Confirm Password"
											// placeholder={t("confirm password")}
										/>
										<span className="input-error" role="alert">{error.passwordConfirm}</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<FormGroup className="MuiFormControl-root MuiTextField-root textfield mb-3" style={{ marginTop: "0.5%" }}>
									{/*[START:ADDRESS]*/}
									<div className="google-captch-wrap ">
										{" "}
										<label className="col-form-label w-100">
											Address
											{validation["address"] && validation["address"].mandatory ? <span className="ele-mandate">*</span> : ""}
											<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={tooltip}>
												<Button className="default">?</Button>
											</OverlayTrigger>
											<GooglePlaces required={validation["address"] && validation["address"].mandatory} errorMessage={error.address} getPlaceHandler={googleSelectedAddressHandler} placeholder="Enter your location" value={data.address} handleChange={handleChange} />
										</label>	
									</div>
									<span className="input-error" id="name-helper-text" role="alert">
										{error.address}
									</span>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<FormGroup>
									<TextField
										label="Apt No./Unit No."
										className="textfield"
										required={validation["suite"] && validation["suite"].mandatory}
										error={error.suite ? true : false}
										helperText={error.suite}
										InputLabelProps={{
											shrink: true,
										}}
										name="suite"
										id="suite"
										onChange={handleChange}
										onFocus={handleChange}
										value={data.suite}
										//placeholder={"Apt No./Unit No."}
										inputProps={{
											"aria-label": 'Mailing Address',
											"aria-autocomplete": 'street-address'
										}}
										autoComplete="street-address"
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<TextField
										label={t("lang_key_add_state")}
										className="textfield"
										required={validation["state"] && validation["state"].mandatory}
										error={error.state ? true : false}
										helperText={error.state}
										InputLabelProps={{
											shrink: true,
										}}
										name="state"
										id="state"
										value={data.state}
										onChange={handleChange}
										onFocus={handleChange}
										//placeholder={"State"}
										inputProps={{
											"aria-label": 'Enter State',
											"aria-autocomplete": 'state'
										}}
										autoComplete="state"
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<TextField
										label={t("City")}
										className="textfield"
										required={validation["city"] && validation["city"].mandatory}
										error={error.city ? true : false}
										helperText={error.city}
										InputLabelProps={{
											shrink: true,
										}}
										name="city"
										id="city"
										value={data.city}
										onChange={handleChange}
										onFocus={handleChange}
										//placeholder={"City"}
										inputProps={{
											"aria-label": 'Enter City',
											"aria-autocomplete": 'city'
										}}
										autoComplete="city"
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<TextField
										label={t("Zipcode")}
										className="textfield"
										required={validation["zip"] && validation["zip"].mandatory}
										error={error.zip ? true : false}
										helperText={error.zip}
										InputLabelProps={{
											shrink: true,
										}}
										name="zip"
										id="zip"
										onChange={handleChange}
										onFocus={handleChange}
										value={data.zip}
										//placeholder={"Zipcode"}
										inputProps={{
											"aria-label": 'Area Zip Code',
											"aria-autocomplete": 'postal-code'
										}}
										autoComplete="postal-code"
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<FormControl className="textfield show-placeholder">
										<InputLabel shrink={true} htmlFor="dobenter">
											{t("Birth Date")}
											{validation["birth"] && validation["birth"].mandatory ? <span className="ele-mandate">*</span> : ""}
										</InputLabel>
										<InputMask
											mask="00/00/1000"
											maskChar={""}
											disabled={underAge}
											value={data.birth}
											id="dobenter"
											formatChars={formatChars}
											onChange={(e) => {
												if (!underAge) handleDate(e.target.value);
											}}
											placeholder="MM/DD/YYYY"
											autoComplete="bday"
											>
											{(inputProps) => <Input disabled={underAge} {...inputProps} />}
											
										</InputMask>
										<span className="input-error" id="name-helper-text" role="alert">
											{error.birth}
										</span>
									</FormControl>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<FormControl className="textfield">
										<InputLabel shrink={true} htmlFor={'rewardType-id'}>
											Reward Type<span className="ele-mandate">*</span>
										</InputLabel>
										<Select
											onChange={handleChangeAdditional}
											name="rewardType"
											//labelId="register-gender-label"
											label="Reward"
											inputProps={{id: 'rewardType-id'}}
											value={data.additionalInfo.rewardType}
											id="rewardType"
											required={true}>
											<MenuItem value="">{t("Select reward Type")}</MenuItem>
											{rewardTypeList.map((v,i)=>{
												return <MenuItem value={v.id}>{v.name}</MenuItem>
											})}
										</Select>
										<span className="input-error" id="name-helper-text" role="alert">
											{error.rewardType}
										</span>
										
									</FormControl>
								</FormGroup>
							</Col>
						</Row>
						<Row md={1} sm={1} xs={1}>
							<Col>
								<p className="text-left mt-4">
									By registering, I agree to receive emails from Oral-B and other trusted {" "}
									<a href="https://us.pg.com/brands/" target="_blank">
										P&G brands
									</a>{" "}
									and programs. Click to read P&G{" "}
									<a href="https://termsandconditions.pg.com/en-us/" target="_blank">
										Terms and Conditions
									</a>{" "}
									and{" "}
									<a href="https://privacypolicy.pg.com/en/" target="_blank">
										Privacy Policy
									</a>
									.
								</p>
							</Col>
						</Row>
						<div className="clearfix"></div>
						{captchaEnable ? (
							<FormGroup className="captcha-center mt-0">
								<label htmlFor="g-recaptcha-response" className="invisible" aria-hidden="true">Google Captcha</label>
								<Captcha position={"left"} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
								<span className="input-error" style={{ marginTop: "-12px" }} role="alert">
									{" "}
									{error.captcha}
								</span>
							</FormGroup>
						) : (
							""
						)}

						<Row md="1">
							<Col>
								<Button type="submit" id="register-submit-btn" role="submit" onClick={onSubmit}>
									<Trans>Register</Trans>
								</Button>
							</Col>
						</Row>
					</form>
				</Card>
			</MuiThemeProvider>
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Body>
					<div className="m-4 text-center">
						<p>
							We're sorry, but we can't accept your messages or personal information because you do not meet our eligibility requirements. But that doesn't mean you can't explore the rest of our site and learn more about us! Check out{" "}
							<a href="https://us.pg.com/" target="_blank">
								www.pg.com
							</a>{" "}
							for information about P&G and its brands.
						</p>
					</div>
					<div className="justify-content-center text-center mt-2">
						<Button variant="secondary" className="btn btn-primary" onClick={handleClose}>
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</Container>
		</main>
	);
};

export default connect(null, { signup, login })(IsLoadingHOC(Register, "Wait ....."));
