import React from "react";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import CopyRight from "./../CopyRight";
//import { useTranslation } from "react-i18next";
const Footer = () => {
	//const { t } = useTranslation();
	return (
		<footer>
			<Container fluid className="footer">
				<div className="footer-link my-4" aria-label="footerMenu">
					<ul>
						<li>
							<a href="https://www.pg.com/privacy/english/privacy_statement.shtml" target="_blank">
								Privacy
							</a>
						</li>
						<li>|</li>
						<li>
							<a href="https://www.pg.com/privacy/english/privacy_statement.shtml#CCPA" target="_blank">
								CA Privacy
							</a>
						</li>
						<li>|</li>
						<li>
							<a href="/termsandconditions">
								Offer Terms &amp; Conditions
							</a>
						</li>
						<li>|</li>
						<li>
							<a href="https://www.pg.com/en_US/terms_conditions/index.shtml" target="_blank">
								Terms and Conditions
							</a>
						</li>
						{/* <li>|</li>
						<li>
							<a href="https://privacypolicy.pg.com/en/#iba" target="_blank" >
								AdChoices<img src="/public/assets/images/AdChoicesicon.png" alt="Adchoices logo" className="adchoices" />
							</a>
						</li> */}
					</ul>
				</div>
				<div className="row justify-content-center footer-offer">
					<div className="col-12">
						<p className="text-center">
							Buy $100/$150/$200* or more (after any coupon or discount applied and before taxes) of qualifying Oral-B® electric toothbrush, or brush head refill products** with your Mastercard® between November 1, 2022 & December 31, 2022 and receive a $20/$35/$60 Mastercard Prepaid Card (respectively). Terms and Conditions apply. Canadian Residents only, excluding residents of Quebec. 19 years of age or order. Maximum one (1) Reward per approved submission. Limit two (2) Reward(s) per person. While supplies last. Proof of purchase required. Issued by mail or email as a digital card. Eligible purchases must be made at a Canadian in-store or online retailer. Submissions must be received by January 31, 2023 at 11:59 PM ET. 
						</p>
					</div>
				</div>
			</Container>
			<CopyRight />
		</footer>
	);
};
export default connect(null, { getManu })(Footer);
