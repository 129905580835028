import React,{useState,useEffect} from "react";
import CarouselCustom from "../../components/CarouselCustom";
import ParticipateInstructions from "../../components/ParticipateInstructions";
import { useSelector } from 'react-redux';
const Home =()=> {
	const appData = useSelector(state => state.app);
	const [isActiveSite, setIsActiveSite] = useState(true);
	useEffect(() => {
	  if (appData.currentMenu) {
		const violation = document.getElementById(appData.currentMenu);
		if (violation) {
		  window.scrollTo({
			top: violation.offsetTop,
			behavior: 'smooth',
		  });
		}
	  }
	  if (appData.config.campaignState === 'partialExpired' || appData.config.submissionEnded === 'submissionExpired') {
		setIsActiveSite(false);
	  }
	  //eslint-disable-next-line
	}, [appData]);
		return (
			<div>
				<CarouselCustom />
				<div className="container-fluid">{isActiveSite ? <ParticipateInstructions /> : <h1 class="text-center">SORRY, THIS PROMOTION HAS ENDED.</h1>}</div>
			</div>
		);
	
}

export default Home;
