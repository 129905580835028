import React from "react";
import { useSelector } from "react-redux";
import { carouselData } from "../data";

const CarouselCustom = () => {
	const user = useSelector(({ user }) => user);
	const config = useSelector(state => state.app.config);
	const [isActiveSite, setIsActiveSite] = React.useState(true);
	React.useEffect(() => {
		if (config.campaignState === 'partialExpired' || config.submissionEnded === 'submissionExpired') {
		  setIsActiveSite(false);
		}
		//eslint-disable-next-line
	  }, [config]);
	return (
		<div className="justify-content-center" role="banner">
			{carouselData.map((item, index) => {
				return (
					<>
						{!user.accessToken  && isActiveSite? (
							<a href="/register">
								<img className="w-100 banner-img"
									src={item.img}
									sizes="(min-width: 400px) 80vw, 100vw"
									srcSet="/public/assets/images/PG_OralB-Hero_Mobile.jpg 375w, /public/assets/images/PG_OralB-Hero-Desktop.jpg 1500w"
									alt={item.title}
									// aria-labelledby="banner-text"
								/>
								{/* <img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} aria-labelledby="banner-text" />
								<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} aria-labelledby="banner-text" /> */}
							</a>
						) : (
							<>
								<img className="w-100 banner-img"
									src={item.img}
									sizes="(min-width: 400px) 80vw, 100vw"
									srcSet="/public/assets/images/PG_OralB-Hero_Mobile.jpg 375w, /public/assets/images/PG_OralB-Hero-Desktop.jpg 1500w"
									alt={item.title}
									// aria-labelledby="banner-text"
								/>
							</>
						)}
					</>
				);
			})}
			{/* <p id="banner-text" className="sr-only sr-only-focusable">Spend more & get more event (Offer Valid: November 1 to December 31, 2022) Spend $200 & get $60 on a prepaid Mastercard, Spend $150 & get $ 35 on a prepaid Mastercard, and spend $100 & get $20 on a prepaid Mastercard. When you buy qualifying Oral-B Rechargeable Electric toothbrush products with your Mastercard.</p> */}
		</div>
	);
};

export default CarouselCustom;
