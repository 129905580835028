import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card, Button, Nav } from "react-bootstrap";
const FrequentlyAskedQuestions = () => {
	const [data, setData] = useState([
		{ id: 0, clicked: false, name: "How long do I have to submit my receipt?", content: "All products must be purchased between 11/01/2022 and 12/31/2022 . You have until 01/31/2023 at 11:59PM ET to upload your receipt." },
		{ id: 1, clicked: false, name: "What do I do if I am having trouble participating in the program?", content: "You can submit any questions you may have about the promotion to our <a href='/support'>support box</a>." },
		{ id: 2, clicked: false, name: "What are the participating products?", content: "<a href='/public/assets/doc/Oral-B-Participating-Products.pdf' target='_blank' aria-label='Click to see product list'>Click here</a> to see the full list of participating products." },
		{ id: 3, clicked: false, name: "How many times can I submit the same valid receipt?", content: "You can only submit a valid receipt once, receipts submitted more than once will be rejected." },
		{ id: 4, clicked: false, name: "How many rewards can I get?", content: "There is a limit of one reward per email address." },
		{ id: 5, clicked: false, name: "Do I have to purchase all $100 of participating products in one transaction?", content: "Yes, you must purchase $100 or more of participating products on one transaction between 10/01/2022 and 12/31/2022. The denomination of Mastercard you can receive will be based on how much you purchase: <ul><li>Spend $100 get $20</li><li>Spend $150 get $35</li><li>Spend $200 get $60</li></ul>" },
		{ id: 8, clicked: false, name: "What do I do if I have additional questions?", content: "Review the Terms and Conditions for the offer for additional details. If the Terms and Conditions have not answered your question, please email us via the <a href='/support'>support box</a>." },
		
	]);
	const [faqStatus, setFaqStatus] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<main id="main">
		<Container>
			<Row className="justify-content-md-center section-content faq" id="faq-content">
				<Col md={12}>
					<h1 className="section-heading text-center pb-3">Frequently Asked Questions</h1>
				</Col>
				<Col md={11} className="text-right my-3">
					<Button onClick={bulkActionHandler} variant="link">
						{!faqStatus ? "Open All" : "Close All"}
					</Button>
				</Col>
				<Col md={11}>
					<Accordion>
						{data.map((v, i) => {
							return (
								<Card key={`card-${i}`} style={{ marginBottom: "1rem" }}>
									<Card.Header>
										<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" onClick={(e) => toggleHandler(i)} eventKey={v.id}>
											<Row style={{ cursor: "pointer" }}>
												<Col xs={11}>{v.name}</Col>
												<Col xs={1} className="text-right pl-0 faq_open-close-icon">
													{v.clicked ? "−" : "+"}
												</Col>
											</Row>
										</Accordion.Toggle>
									</Card.Header>
									{v.clicked ? (
										<Accordion>
											<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
										</Accordion>
									) : (
										<Accordion.Collapse>
											<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
										</Accordion.Collapse>
									)}
								</Card>
							);
						})}
					</Accordion>
				</Col>
			</Row>
		</Container>
		</main>
	);
};

export default FrequentlyAskedQuestions;
