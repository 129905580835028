import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { toast } from "react-toastify";
import { uploadImageAction, validateUploadReceiptAction } from "../../redux/actions";
import PreviousReceiptList from "./PreviousReceiptList";
import FileDropZone from "../FileDropZone";
import { useSelector } from "react-redux";
import Captcha from "../Captcha";
import { generatePdfFromImages } from "../../hooks/ImagesToPDF";
const UploadReceipt = (props) => {
	const { t } = useTranslation();
	const config = useSelector((state) => state.app.config);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	let [selectedFile, setSelectedFile] = useState([]);
	const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
	let [enableUploading, setEnableUploading] = useState(true);
	React.useEffect(() => {
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(false);
		}
		if (config.captchaConfig) {
			if (config.captchaConfig.uploadReceiptCaptcha) {
				setCaptchaEnable(true);
			}
		}
		//eslint-disable-next-line
	}, [config]);
	const checkUploadEnableOrNot = (uploadApiCall) => {
		props.setLoading(true);
		validateUploadReceiptAction({})
			.then((responseData) => {
				props.setLoading(false);
				//setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] >= 200 && responseData["httpCode"] <= 299) {
					setEnableUploading(responseData.data.valid);
					if (uploadApiCall) {
						uploadFileHandler(responseData.data.token);
					}
					return true;
				} else {
					toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				}
			})
			.catch((err) => {
				console.log("error", err);
				toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				props.setLoading(false);
			});
	};
	const uploadImage = () => {
		if (selectedFile.length === 0) {
			toast.error(`${t("Error")}! ${t("Select a receipt")}`);
			return false;
		}
		if (!captchaValue && captchaEnable) {
			toast.error(`${t("Error")}! ${t("Please select captcha")}`);
			return false;
		}
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(true);
		} else {
			uploadFileHandler("");
		}
	};
	const uploadFileHandler = async (token) => {
		let fileData = selectedFile[0];
		if (selectedFile.length > 1) {
			fileData = await generatePdfFromImages(selectedFile);
		}
		const formData = new FormData();
		formData.append("type", "receipt");
		formData.append("image", fileData);
		if (token) {
			formData.append("token", token);
		}
		if (captchaEnable) {
			formData.append("captchaVal", captchaValue);
		}
		props.setLoading(true);
		uploadImageAction(formData)
			.then((responseData) => {
				props.setLoading(false);
				window.scroll({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
				setResetCaptchaValue(resetCaptchaValue + 1);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] === 200) {
					toast.success("Your receipt/s have been uploaded successfully. We shall get back to you in one to two business days.");
					setSelectedFile([]);
					return true;
				} else if (responseData["httpCode"] === 409) {
					toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
					setSelectedFile([]);

					return true;
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);

					return false;
				}
			})
			.catch((err) => {
				setResetCaptchaValue(resetCaptchaValue + 1);
				console.log("error", err);
				props.setLoading(false);
				if (typeof err === "object") {
					if (err["response"]) {
						if (err["response"].httpCode === 409) {
							//duplicate Receipt message
							toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
							setSelectedFile([]);
							return false;
						} else {
							toast.error(`${t("Error")}! ${t("Something went wrong")}`);
							return false;
						}
					} else {
						toast.error(`${t("Error")}! ${t("Something went wrong")}`);
						return false;
					}
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			});
	};
	const resetAllSelectionBtnClick = () => {
		setSelectedFile([]);
		setResetCaptchaValue(resetCaptchaValue + 1);
	};
	return (
		<main id="main">
		<Container fluid>
			<div className="validate-purchase-div">
				<Col>
					<Row xs={1} md={1} lg={1}>
						<h1 className="section-heading heading text-center">
							<Trans>Upload your receipt</Trans>
						</h1>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<div className="h2-disc1" role="heading" aria-level="2">
							<Trans>Take a clear picture of your entire receipt. The receipt must include the name and date of purchase on the photo.</Trans>
						</div>
						<p className="text-center mt-2">
							<Trans>Click on the box below to upload your receipt. 
								{/* Images will be accepted in JPG, PNG, or PDF format per entry. */}
								</Trans>
						</p>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<p className="text-center mt-3">
							{/* <Trans>Note</Trans>: <Trans>Size of the receipt must be less than</Trans> 5 MB. */}
							<strong><Trans>Note</Trans>:</strong> Uploaded images should be the same format - JPG, PNG, or PDF are accepted. If a combination of images and PDF are selected only the last PDF will be uploaded.
						</p>
					</Row>
					<Row className="justify-content-md-center">
						<Col md="12">
							<PreviousReceiptList reloadCounter={receiptLoadCounter} />
						</Col>
					</Row>
					<Row>
						<FileDropZone enableUploading={enableUploading} parentCallBack={setSelectedFile} selectedFile={selectedFile} />
					</Row>
					{captchaEnable ? (
						<Row md={1} className="mt-3">
							<Col>
								<label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
									Google captcha
								</label>
								<Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
							</Col>
						</Row>
					) : (
						""
					)}
					{enableUploading ? (
						<Row md={2} xs={2} sm={2}>
							<Col className="text-right">
								<Button size="lg" type="button" onClick={uploadImage} className="btn btn-primary" id="upload-btn">
									<Trans>Submit</Trans>
								</Button>
							</Col>
							<Col>
								<Button size="lg" type="button" onClick={resetAllSelectionBtnClick} className="btn btn-warning" id="upload-btn">
									<Trans>Reset</Trans>
								</Button>
							</Col>
						</Row>
					) : (
						""
					)}
				</Col>
			</div>
		</Container>
		</main>
	);
};

export default IsLoadingHOC(UploadReceipt, "Loading....");
