import React, { Fragment } from "react";
import { logo } from "../data/index";
import { Link } from "react-router-dom";
const Logo = () => {
	return (
		<Fragment>
			<span className="navbar-brand" aria-label="Brand Logo">
				<img alt="Oral-B Logo" src={logo}></img>
			</span>
		</Fragment>
	);
};
export default Logo;
